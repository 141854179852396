<div
  *ngIf="topSnackbarVisible"
  class="bg-bluegray-900 text-gray-100 p-3 flex justify-content-between lg:justify-content-center align-items-center flex-wrap"
>
  <div class="font-bold mr-8"></div>
  <div class="align-items-center hidden lg:flex">
    <span class="line-height-3">
      Grow your visibility across sports enthusiasts and fans.
    </span>
  </div>
  <a
    class="text-white flex align-items-center ml-2 mr-8"
    href="https://www.chaseyoursport.com/advertise"
    target="_blank"
  >
    Contact Us
  </a>
  <a
    pRipple
    class="flex align-items-center no-underline justify-content-center border-circle text-gray-50 hover:bg-bluegray-700 cursor-pointer transition-colors transition-duration-150"
    style="width: 2rem; height: 2rem"
    (click)="toggleTopSnackbar()"
  >
    <i class="pi pi-times"></i>
  </a>
</div>

<div class="header-wrapper">
  <p-toolbar styleClass="custom-toolbar">
    <div class="p-toolbar-group-left">
      <button (click)="sidebarVisible = true" aria-label="Open Sidebar">
        <i class="pi pi-bars"></i>
      </button>
      <div class="logo-container" routerLink="/">
        <img
          src="/assets/images/sports-social-logo.png"
          alt="Logo"
          class="logo"
        />
        <div class="logo-text">
          <div class="logo-title">Chase Your Sport</div>
          <div class="logo-subtitle">Sports Social Blog</div>
        </div>
      </div>
    </div>
    <div class="p-toolbar-group-right do-not-show-in-tab-and-mobile">
      <a
        href="https://go.roobetaffiliates.com/visit/?bta=38944&nci=6788&utm_campaign=&utm_medium=banner&utm_campaign=SB"
        target="_blank"
        ><img
          src="/assets/images/navigation-advertisement.gif"
          alt="navigation-advertisement"
      /></a>
      <a pButton class="toolbar-buttons" routerLink="/search"
        ><i class="pi pi-search"></i
      ></a>
      <a pButton class="toolbar-buttons" routerLink="/contact">Contact</a>
      <a
        pButton
        class="toolbar-buttons"
        routerLink="/login"
        *ngIf="!isUserLoggedIn"
      >
        <i class="pi pi-sign-in"></i> Sign In
      </a>
      <div
        class="user-avatar"
        *ngIf="isUserLoggedIn"
        (click)="op.toggle($event)"
      >
        <img
          *ngIf="
            userDetails?.profilePicture &&
            !userDetails.profilePicture.includes('prod.sportsocial.in')
          "
          [src]="updateImageUrl(userDetails.profilePicture)"
          alt="{{ userDetails.name }}"
        />
        <div
          *ngIf="
            !userDetails?.profilePicture ||
            userDetails.profilePicture.includes('prod.sportsocial.in')
          "
          class="user-initials"
        >
          {{ initials }}
        </div>
      </div>
    </div>
  </p-toolbar>
  <div class="global-border do-not-show-in-tab-and-mobile"></div>
</div>
<div
  class="p-d-flex p-jc-center p-ai-center custom-navbar do-not-show-in-tab-and-mobile"
>
  <a class="p-mr-2" routerLink="/">Home</a>
  <a
    class="p-mx-2"
    routerLink="{{ slugify(topic.topicName) }}"
    *ngFor="let topic of trendingTopics"
    >{{ topic.topicName }}</a
  >
  <a class="p-mx-2" routerLink="/topic/explore-topics">Explore Topics</a>
</div>

<p-sidebar
  [(visible)]="sidebarVisible"
  position="left"
  styleClass="custom-sidebar"
  [modal]="false"
>
  <!-- Header with logo -->
  <div class="custom-sidebar-header">
    <img src="/assets/images/sports-social-logo.png" alt="Logo" class="logo" />
  </div>

  <div class="global-border"></div>

  <!-- Scrollable content area -->
  <div class="sidebar-content">
    <div class="sidebar-title">Find</div>
    <div class="sidebar-section">
      <a
        routerLink="/search"
        class="sidebar-item"
        (click)="sidebarVisible = false"
        ><i class="pi pi-search"></i> Search</a
      >
    </div>
    <div class="global-border"></div>

    <div class="sidebar-section">
      <div class="sidebar-title">
        <i class="fa fa-fire" aria-hidden="true"></i> Trending
      </div>
      <a
        routerLink="/{{ slugify(topic.topicName) }}"
        class="sidebar-item"
        *ngFor="let topic of trendingTopics"
        (click)="sidebarVisible = false"
        >{{ topic.topicName }}</a
      >
      <a
        (click)="sidebarVisible = false"
        routerLink="/topic/explore-topics"
        class="sidebar-item"
        >Explore Topics</a
      >
    </div>
    <div class="global-border"></div>
    <div class="sidebar-section">
      <div class="sidebar-title">Get In Touch</div>
      <a
        routerLink="/about"
        class="sidebar-item"
        (click)="sidebarVisible = false"
        >About</a
      >
      <a
        routerLink="/contact"
        class="sidebar-item"
        (click)="sidebarVisible = false"
        >Contact</a
      >
      <a
        routerLink="/advertise"
        class="sidebar-item"
        (click)="sidebarVisible = false"
        >Advertise With Us</a
      >
    </div>
    <div class="global-border"></div>

    <div class="sidebar-section">
      <div class="sidebar-title">Organization</div>
      <a
        routerLink="/privacy-policy"
        class="sidebar-item"
        (click)="sidebarVisible = false"
        >Privacy Policy</a
      >
    </div>
  </div>

  <!-- Footer with profile info -->
  <div class="sidebar-footer">
    <a
      routerLink="/login"
      class="sidebar-item"
      *ngIf="!isUserLoggedIn"
      (click)="sidebarVisible = false"
      ><i class="pi pi-sign-in"></i>Sign In</a
    >
    <div class="user-avatar" *ngIf="isUserLoggedIn" (click)="op.toggle($event)">
      <img
        *ngIf="
          userDetails?.profilePicture &&
          !userDetails.profilePicture.includes('prod.sportsocial.in')
        "
        [src]="updateImageUrl(userDetails.profilePicture)"
        alt="{{ userDetails.name }}"
      />
      <div
        *ngIf="
          !userDetails?.profilePicture ||
          userDetails.profilePicture.includes('prod.sportsocial.in')
        "
        class="user-initials"
      >
        {{ initials }}
      </div>
    </div>
    <div
      class="profile-name"
      *ngIf="isUserLoggedIn"
      routerLink="/user/profile"
      (click)="sidebarVisible = false"
    >
      {{ userDetails?.name }}
    </div>
  </div>
</p-sidebar>

<!-- OverlayPanel component -->
<p-overlayPanel #op>
  <ul>
    <li>
      <a routerLink="/user/profile"><i class="pi pi-user"></i> Profile</a>
    </li>
    <li>
      <a routerLink="/user/create/article"><i class="pi pi-pencil"></i>Write</a>
    </li>
    <li>
      <a routerLink="/user/edit-profile"
        ><i class="pi pi-user-edit"></i>Edit Profile</a
      >
    </li>
    <!-- <li>
      <a href="/user/profile/stats"><i class="pi pi-chart-bar"></i>Stats</a>
    </li> -->
    <li *ngIf="userRole === 'Admin' || userRole === 'Editor'">
      <a routerLink="/dashboard"><i class="pi pi-briefcase"></i>Dashboard</a>
    </li>
    <li>
      <button (click)="signOut()">
        <i class="pi pi pi-sign-out"></i>Sign out
      </button>
    </li>
  </ul>
</p-overlayPanel>
